export function nav() {
	const navParent = document.querySelectorAll('.nav__parent'),
				navChild = document.querySelectorAll('.nav__children'),
				maxParent = navParent.length;
	let activeIndex = maxParent;

	for (let i = 0; i < maxParent; i++) {
		const navOpen = () => {
			if(activeIndex < maxParent){
				navParent[activeIndex].setAttribute('aria-expanded', navParent[activeIndex].getAttribute('aria-expanded').replace(/true/, 'false'));
				navChild[activeIndex].setAttribute('aria-hidden', navChild[activeIndex].getAttribute('aria-hidden').replace(/false/, 'true'));
			}

			navParent[i].setAttribute('aria-expanded', navParent[i].getAttribute('aria-expanded').replace(/false/, 'true'));
			navChild[i].setAttribute('aria-hidden', navChild[i].getAttribute('aria-hidden').replace(/true/, 'false'));

			activeIndex = i;
		};

		const navClose = () => {
			navParent[i].setAttribute('aria-expanded', navParent[i].getAttribute('aria-expanded').replace(/true/, 'false'));
			navChild[i].setAttribute('aria-hidden', navChild[i].getAttribute('aria-hidden').replace(/false/, 'true'));

			activeIndex = maxParent;
		};

		if(matchMedia('(min-width: 768px)').matches){
			navParent[i].addEventListener('mouseenter', navOpen);
			navParent[i].addEventListener('mouseleave', navClose);
			navParent[i].addEventListener('focus', navOpen);
			navParent[i].addEventListener('blur', navClose);

			navChild[i].addEventListener('mouseenter', navOpen);
			navChild[i].addEventListener('mouseleave', navClose);
			navChild[i].addEventListener('focusin', navOpen);
			navChild[i].addEventListener('focusout', navClose);
		}

		navParent[i].addEventListener('click', () => {
			const btnState = navParent[i].getAttribute('aria-expanded');

			if(btnState === 'false'){
				navOpen();
			} else {
				navClose();
			}
		});
	}
}
