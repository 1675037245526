export function accordion() {
	const accordionBtn = document.querySelectorAll('.page-nav__btn');

	for (var i = 0; i < accordionBtn.length; i++) {
		accordionBtn[i].addEventListener('click', function(){
			const accordion = this.nextElementSibling;

			this.classList.toggle('is-opened');

			if (accordion.style.height) {
				accordion.style.height = null;
				accordion.setAttribute('aria-hidden', accordion.getAttribute('aria-hidden').replace(/false/, 'true'));

				this.setAttribute('aria-expanded', this.getAttribute('aria-expanded').replace(/true/, 'false'));
			} else {
				accordion.style.height = accordion.scrollHeight + 'px';
				accordion.setAttribute('aria-hidden', accordion.getAttribute('aria-hidden').replace(/true/, 'false'));

				this.setAttribute('aria-expanded', this.getAttribute('aria-expanded').replace(/false/, 'true'));
			}
		});
	}
}
