export function navForm() {
	const navFormBtn = document.querySelector('.header__search'),
				navForm = document.getElementById('nav-form'),
				header = document.querySelector('.header');

	navFormBtn.addEventListener('click', () => {
		const btnState = navFormBtn.getAttribute('aria-expanded');

		if(btnState === 'false'){
			navFormBtn.setAttribute('aria-expanded', btnState.replace(/false/, 'true'));
			navForm.setAttribute('aria-hidden', navForm.getAttribute('aria-hidden').replace(/true/, 'false'));
			// header.classList.add('is-search');
		} else {
			navFormBtn.setAttribute('aria-expanded', btnState.replace(/true/, 'false'));
			navForm.setAttribute('aria-hidden', navForm.getAttribute('aria-hidden').replace(/false/, 'true'));
			// header.classList.remove('is-search');
		}
	});
}
