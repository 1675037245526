export function tab() {
	if(document.getElementsByClassName('-top').length){
		const f_tabBtn = document.querySelectorAll('.feature__btn'),
					f_tabPanel = document.querySelectorAll('.feature__item');
		let f_activeIndex = 0;

		for (let f_index = 0; f_index < f_tabBtn.length; f_index++) {
			f_tabBtn[f_index].addEventListener('click', () => {
				if(f_activeIndex === f_index) return;

				f_tabBtn[f_activeIndex].setAttribute('aria-selected', f_tabBtn[f_activeIndex].getAttribute('aria-selected').replace(/true/, 'false'));
				f_tabPanel[f_activeIndex].setAttribute('aria-hidden', f_tabPanel[f_activeIndex].getAttribute('aria-hidden').replace(/false/, 'true'));

				f_tabBtn[f_index].setAttribute('aria-selected', f_tabBtn[f_index].getAttribute('aria-selected').replace(/false/, 'true'));
				f_tabPanel[f_index].setAttribute('aria-hidden', f_tabPanel[f_index].getAttribute('aria-hidden').replace(/true/, 'false'));

				f_tabBtn[f_index].blur();
				f_tabPanel[f_index].firstElementChild.focus();

				f_activeIndex = f_index;
			});
		}

		const a_tabBtn = document.querySelectorAll('.top-archive__btn'),
					a_tabPanel = document.querySelectorAll('.top-archive__item');
		let a_activeIndex = 0;

		for (let a_index = 0; a_index < a_tabBtn.length; a_index++) {
			a_tabBtn[a_index].addEventListener('click', () => {
				if(a_activeIndex === a_index) return;

				a_tabBtn[a_activeIndex].setAttribute('aria-selected', a_tabBtn[a_activeIndex].getAttribute('aria-selected').replace(/true/, 'false'));
				a_tabPanel[a_activeIndex].setAttribute('aria-hidden', a_tabPanel[a_activeIndex].getAttribute('aria-hidden').replace(/false/, 'true'));

				a_tabBtn[a_index].setAttribute('aria-selected', a_tabBtn[a_index].getAttribute('aria-selected').replace(/false/, 'true'));
				a_tabPanel[a_index].setAttribute('aria-hidden', a_tabPanel[a_index].getAttribute('aria-hidden').replace(/true/, 'false'));

				a_activeIndex = a_index;
			});
		}
	}
}
