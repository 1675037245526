// import Swiper from 'swiper';

export function swiper() {
	if(document.getElementsByClassName('mv__image').length > 1){
		const fadeSlide = {
			autoplay: {
				delay: 4000,
				disableOnInteraction: true
			},
		  loop: true,
			speed: 2000,
			effect: 'fade',
		};

		new Swiper('.mv__slide', fadeSlide);
	}

	// if(document.getElementsByClassName('l-front').length){
	// 	const linerSlide = {
	// 		autoplay: {
	// 			delay: 0,
	// 			disableOnInteraction: true
	// 		},
	// 	  loop: true,
	// 		speed: 8000,
	// 	  autoHeight: false,
	// 		slidesPerView	: 3.5,
	// 		centeredSlides: true,
	// 		spaceBetween: 21,
	// 		breakpoints: {
	// 			760: {
	// 	      slidesPerView: 1.5,
	// 	      spaceBetween: 20
	// 	    },
	// 	  },
	// 	};
	//
	// 	new Swiper('.xxx-2__wrap', linerSlide);
	//
	// 	// 画面幅によってスライドon/off
	// 	let xxxxxSlide, xxxxxSwiperBool;
	//
	// 	window.addEventListener('load',()=>{
	// 		if(matchMedia('(max-width: 760px)').matches){
	// 	    createSwiper();
	// 	    xxxxxSwiperBool = false;
	// 	  } else {
	// 			xxxxxSwiperBool = true;
	// 	  }
	// 	},false);
	//
	// 	window.addEventListener('resize',()=>{
	// 		if(matchMedia('(max-width: 760px)').matches && xxxxxSwiperBool){
	// 			createSwiper();
	// 			xxxxxSwiperBool = false;
	// 	  } else if(matchMedia('(min-width: 761px)').matches && !(xxxxxSwiperBool)) {
	// 			xxxxxSlide.destroy(false,true);
	// 	    xxxxxSwiperBool = true;
	// 	  }
	// 	},false);
	//
	// 	const createSwiper = () =>{
	// 	  xxxxxSlide = new Swiper('.front-news__wrap', {
	// 			autoplay: {
	// 				delay: 4000,
	// 				disableOnInteraction: true
	// 			},
	// 			loop: true,
	// 			speed: 2000,
	// 			autoHeight: false,
	// 			slidesPerView	: 1.25,
	// 			centeredSlides: true,
	// 			spaceBetween: 40,
	// 	  });
	// 	}
	// }
}
