import {toc} from "./toc";

export function smoothScrollVanilla() {
	toc();

	const scrollToAnker = (hash) => {
		const target = document.querySelector(hash),
					rectTop = target.getBoundingClientRect().top,
					offsetTop = window.pageYOffset,
					buffer = document.querySelector('.header').clientHeight,
					top = rectTop + offsetTop - buffer;

		window.scrollTo({
		  top,
		  behavior: "smooth"
		});
	}

	const anker = document.querySelectorAll('a[href^="#"]');
	//URLのハッシュ値を取得
	const urlHash = location.hash;
	//ハッシュ値があればページ内スクロール
	if(urlHash) {
		setTimeout( () => {
			scrollToAnker(urlHash) ;
		}, 300);
	}

	for (let i = 0; i < anker.length; i++) {
		anker[i].addEventListener('click', e => {
	    e.preventDefault();

			const href = anker[i].getAttribute('href'),
						hash = href == '#' || href == '' ? '#top' : href;

			scrollToAnker(hash);
	  });
	}
}
