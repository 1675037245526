export function toc() {
  const indexWrap = document.getElementById('toc');

  if (indexWrap) {
    const postContent = document.querySelector('.the_content'),  //記事本文が書かれているラッパー
          headings = postContent.querySelectorAll('h2, h3, h4'),
          indexList = document.createElement("span");
    let parentLevel = 1,
        cursorNode = indexWrap;

    indexWrap.appendChild(indexList);
    indexList.classList.add('toc__wrap');


    for (let i = 0; i < headings.length; i++) {
      const currentHeading = headings[i],
            newLevel = parseInt(currentHeading.tagName.slice(1)),
            diff = newLevel - parentLevel,
            ulNode = document.createElement('ul'),
            liNode = document.createElement('li');

      currentHeading.setAttribute('id', "index-" + i);  //リンクで飛べるようにIDをつける

      if (diff > 0) {
        const containerLiNode = cursorNode.lastChild;

        containerLiNode.appendChild(ulNode);
        // console.log(i, containerLiNode)

        cursorNode = ulNode;
        parentLevel = newLevel;
      }

      if (diff < 0) {
        while (0 !== diff++) {
          cursorNode = cursorNode.parentNode.parentNode;
        }
        parentLevel = newLevel;
      }

      if (currentHeading.hasAttribute('id')) {
        const link = document.createElement('A');
        link.setAttribute('href', '#' + currentHeading.getAttribute('id'));
        link.appendChild(document.createTextNode(currentHeading.textContent))
        liNode.appendChild(link);
      } else {
        liNode.appendChild(document.createTextNode(currentHeading.textContent));
      }
      cursorNode.appendChild(liNode);
    }
  }
}
