//import objectFitImages from 'object-fit-images'
import smoothscrollPolyfill from 'smoothscroll-polyfill';
import whatInput from 'what-input';

//import {common} from "../modules/common";
import {changeImg} from "../modules/changeImg";
import {smoothScrollVanilla} from "../modules/smoothScrollVanilla";
//import {smoothLink} from "../modules/smoothLink";
//import {pagetop} from "../modules/pagetop";
//import {scrollAnimation} from "../modules/scrollAnimation";
import {nav} from "../modules/nav";
import {navForm} from "../modules/navForm";

import {accordion} from "../modules/accordion";
//import {headerFixed} from "../modules/headerFixed";
//import {scrollCv} from "../modules/scrollCv";
import {tab} from "../modules/tab";
import {snsShare} from "../modules/snsShare";


import {swiper} from "../modules/swiper";
//import {instagram} from "../modules/instagram";

//objectFitImages();
smoothscrollPolyfill.polyfill();
whatInput.ask();

//common();
changeImg();
smoothScrollVanilla();
//smoothLink();
//pagetop();
//scrollAnimation();
nav();
navForm();

accordion();
//headerFixed();
//scrollCv();

tab();
snsShare();

swiper();
// instagram();
